import React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Card, Row, Skeleton, Space, Grid, Col, Anchor, Rate, Image, Button} from "antd";
import {useEffect, useMemo, useState} from "react";
import ajax from "../Utils/APICall";
import { Typography } from 'antd';
import {FolderOpenOutlined, ShareAltOutlined} from "@ant-design/icons";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {notify} from "../Utils/Dialog";


const HtmlToReactParser = require('html-to-react').Parser;

const { Text, Link } = Typography;
const { useBreakpoint } = Grid;

const ViewArticle = () => {
    const {articleId} = useParams();
    const navigate = useNavigate();
    const screens = useBreakpoint();

    const [articleData, setArticleData] = useState(null);
    const [bodyCol, setBodyCol] = useState({body: 18, anchor:6});
    const [anchorList, setAnchorList] = useState([]);

    const [feedbackScore, setFeedbackScore] = useState(0);

    const rateTooltip = ['Rất tệ', 'Chưa tốt', 'Bình thường', 'Tốt', 'Xuất sắc'];

    useEffect(() => {
        ajax.post('article/view', {id: articleId}).then(resp => {
            if (resp === '404'){
                navigate('/404');
                return;
            }

            if (typeof resp === 'object'){
                setArticleData(resp);

                if (resp?.bookmarks?.length > 0){
                    setAnchorList(resp.bookmarks.map(anchor =>  ({
                            key: anchor.bookmarkId,
                            href: `#${anchor.bookmarkId}`,
                            title: anchor.bookmarkTitle
                        })
                    ))
                }

                if (resp?.feedbackScore)
                    setFeedbackScore(resp.feedbackScore);
            }

        })
    }, [articleId])

    useMemo(() => {
        const { lg } = screens;
        const hasAnchor = anchorList.length > 0;

        if (lg === false) {
            setBodyCol({ body: 24, anchor: 0 });
        } else {
            setBodyCol({ body: hasAnchor ? 18 : 24, anchor: hasAnchor ? 6 : 0 });
        }
    }, [screens, anchorList]);

    if (!articleId){
        navigate('/404');
        return(null);
    }

    const rate = (value) => {
        setFeedbackScore(value);
        ajax.post('/feedback', {id: articleData._id, score: value})
    }

    function pToDivImage(htmlString) {
        const doc = new DOMParser().parseFromString(htmlString, 'text/html');
        const imgTags = doc.getElementsByTagName('img');

        for (const imgTag of imgTags) {
            const parentTag = imgTag.parentNode;
            if (parentTag.nodeName === 'P') {
                const divTag = doc.createElement('div');
                parentTag.parentNode.replaceChild(divTag, parentTag);
                divTag.appendChild(imgTag);
            }
        }

        return doc.body.innerHTML;
    }

    function modifyHTML(element) {
        if (typeof element === 'string') {
            return element;
        }

        if (Array.isArray(element)) {
            return element.map((child) => modifyHTML(child));
        }

        if (React.isValidElement(element)) {
            const { type, props } = element;

            if (type === 'img') {
                // Replace <img> tag with the ReactImageZoom component
                return (
                    <Image
                        src={props.src}
                        width={props.width}
                    />
                );
            }

            // Recursively modify children of the element
            const modifiedChildren = React.Children.map(props.children, (child) =>
                modifyHTML(child)
            );

            return React.cloneElement(element, null, modifiedChildren);
        }

        return element;
    }

    const parseHTML = (html) => {

        const htmlToReactParser = new HtmlToReactParser();
        const reactElements = htmlToReactParser.parse(pToDivImage(html));

        //console.log(ReactDOMServer.renderToStaticMarkup(reactElements));

        return modifyHTML(reactElements);
    }


    return(
        !articleData ?
            <div>
                <Skeleton loading active />
                <Skeleton loading active />
            </div>
            :
            <div>
                <Card
                    style={{width: '100%'}}
                    title={<span className='article-title'>{articleData.title}</span>}
                >
                    <Row gutter={[24,0]}>
                        <Col span={bodyCol.body}>
                            <Space direction="vertical">
                                <Text type="secondary"><FolderOpenOutlined />&nbsp;{articleData.category}</Text>
                                <Space>
                                    <Text type="secondary">Cập nhật {articleData.update}</Text>
                                    <Text type="secondary">| Số lần xem: {articleData.viewCount}</Text>
                                    <CopyToClipboard text={`https://helpdesk.forlife.vn/article/view/${articleId}`} onCopy={() => notify("success", "Đã chép link vào clipboard.")}>
                                        <Button title="Chép link to clipboard" icon={<ShareAltOutlined />}></Button>
                                    </CopyToClipboard>
                                </Space>

                                {parseHTML(articleData.viewContent)}
                                <hr />
                                <div style={{display: 'flex', textAlign: 'center', justifyContent: 'center'}}>
                                    <Card style={{width: '100%', alignSelf: 'center', backgroundColor: '#f0f3f5'}}>
                                        <Space direction="vertical">
                                            <span>Bài viết này có hữu ích cho bạn không?</span>
                                            <span><Rate style={{fontSize: '2rem'}} allowClear={false} tooltips={rateTooltip} onChange={rate} defaultValue={feedbackScore}/></span>
                                            <span></span><span></span><span></span><span></span><span></span>
                                            <span>Nếu bạn vẫn chưa giải quyết được vấn đề hoặc không thể tự xử lý được. Vui lòng liên lạc với chúng tôi qua kênh&nbsp;<Link href="https://home.forlife.vn" target="_blank">Ticket</Link>&nbsp;để được hỗ trợ nhé.</span>
                                        </Space>
                                    </Card>
                                </div>
                                <div style={{display: 'flex'}}>

                                </div>
                            </Space>
                        </Col>
                        <Col span={bodyCol.anchor}>
                            <Anchor
                                items={anchorList}
                                style={{paddingTop: '20px'}}
                            />
                        </Col>
                    </Row>
                </Card>

            </div>
    )
}
export default ViewArticle
