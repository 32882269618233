import toast from 'react-hot-toast';
import { Button, Modal } from 'antd';
export const notify = (type, message, duration = 5) => {
    let toastConfig = {
        duration: duration*1000,
        position: 'top-right',
        style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
            padding: '20px'
        },
    }

    switch (type){
        case "error":
            toast.error(message, toastConfig);
            break;
        case "success":
            toast.success(message, toastConfig);
            break;
        default:
            toast.success(message, toastConfig);
    }
}

