import axios from 'axios';
import {notify} from "../Dialog";
import {hasProperty} from "../../../Common";
import {getJWT} from "../../../Common";
axios.interceptors.request.use(function (config) {
    config.baseURL = "https://helpdesk.forlife.vn/api";
    config.headers.set("Authorization", 'Bearer ' + getJWT()?.jwt);

    return config;
}, function (error) {
    console.log("ERROR", error);
    notify("error", error);
    return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
    if ((response !== undefined) && (hasProperty(response, "data.message"))) {

        let message = response.data;

        if ((hasProperty(message, "error")) && (message.error === 1)){
            notify("error", message.message);
            return;
        } else if ((hasProperty(message, "error")) && (message.error === 0)){
            return message.message;
        }

        notify("error", "Lỗi dữ liệu trả về từ hệ thống");
        return null;
    }
}, function (error) {
    if (error?.request?.responseURL == 'https://helpdesk.forlife.vn/api/api/feedback')
        return;

    let errorMessage = '';
    errorMessage = error.message;
    notify("error", errorMessage);
    //return Promise.reject(error);
});


const ajax = axios;
export default ajax;
