import {useEffect} from "react";
import {Content} from "antd/es/layout/layout";
import {getJWT} from "../../Common";
import {UserAtom, UserAtomSchema} from "../Context/UserContext";
import {useAtom} from "jotai";
import {useParams} from "react-router-dom";

export const ProtectedRoute = ({ children }) => {
    const [userAtom, setUserAtom] = useAtom(UserAtom);
    const {articleId} = useParams();

    useEffect(() => {
        const jwt = getJWT();

        if (!jwt) {
            setUserAtom(UserAtomSchema);
        }
    }, [children]);

    if (!userAtom.isLoggedIn){
        if (articleId) {
            window.localStorage.setItem('redirect', JSON.stringify(
                {
                    articleId: articleId,
                    time: Date.now()
                }
            ));
        }
        return (
            <div style={{paddingTop: "50px", textAlign: "center"}}>
                <span>
                    Bạn chưa đăng nhập. Vui lòng đăng nhập trước khi sử dụng.
                </span>
            </div>
        )
    }

    return (
        <Content className="site-layout" style={{ padding: '15px 5px', maxWidth: "1000px", margin: "auto", alignItems: 'center', justifyContent: 'center'}}>
            {children}
        </Content>)
}
