import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import ajax from "../Utils/APICall";
import {Card, List, Typography} from "antd";
const {Title} = Typography;

const BrowseCategory = () => {
    const {categoryId} = useParams()
    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [headData, setHeadData] = useState({});

    useEffect(() => {
        if (!categoryId){
            navigate('/');
            return;
        }

        ajax.post('/category/browse', {catId: categoryId}).then(resp => {
            if (typeof resp === 'object') {
                setHeadData(resp.find(obj => obj.hasOwnProperty('main')));
                setData(resp.filter(obj => obj.main !== true));
            }
        })
    }, [])

    return (
        <div>
            <List
                size="large"
                locale={{emptyText : "Chưa có dữ liệu"}}
                header={<Title level={4}>{headData.categoryName}</Title>}
            />
            {data.map(obj => {
                console.log(obj)
                if (!obj.main) {
                    return (
                        <List
                            key={obj._id}
                            size="large"
                            dataSource={data.articles}
                            renderItem={(item) => (
                                <List.Item>
                                    item.title
                                </List.Item>
                            )}
                            locale={{emptyText : "Chưa có dữ liệu"}}
                            header={<Title level={4}>{obj.categoryName}</Title>}
                        />
                    );
                }
            })}
        </div>
    );
}

export default BrowseCategory;
