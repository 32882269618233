import jwtDecode from "jwt-decode";

export function setJWT(token){
    let jwt;

    try {
        jwt = jwtDecode(token);
    } catch (e) {
        return false;
    }

    let token_data = {
        expires : jwt.exp,
        name    : jwt.name,
        uid     : jwt.uid,
        dept    : jwt.dept,
        role    : jwt.role ?? 0,
        jwt     : token
    };

    window.localStorage.setItem("jwt-token", JSON.stringify(token_data));

    return token_data;
}

export function getJWT() {
    let jwt_payload = null;
    try {
        jwt_payload = JSON.parse(window.localStorage.getItem("jwt-token"));
    } catch (e) {
        return false;
    }

    if ((!jwt_payload) || (isNaN(jwt_payload.expires)) || (jwt_payload.expires < Date.now() / 1000)) { //Token Expired or Invalid Token
        window.localStorage.removeItem("jwt-token");
        return false;
    }

    //Kiểm tra schema của jwt_payload
    const {uid, name, dept, expires} = jwt_payload;
    if (uid && name && dept && expires) {
        return jwt_payload;
    } else {
        return false;
    }
}

export function deleteJWT(){
    window.localStorage.removeItem("jwt-token");
}

export function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export function hasProperty(obj, prop) {
    const props = prop.split(".");
    let current = obj;

    for (const p of props) {
        if (!current || !current.hasOwnProperty(p)) {
            return false;
        }
        current = current[p];
    }

    return true;
}

export function isObjectOrArray(input) {
    return Array.isArray(input) || (typeof input === 'object' && input !== null);
}
export function findObjectById(data, id) {
    for (let obj of data) {
        if (obj._id === id) {
            return obj; // Found the object with the matching ID
        } else if (obj.children && obj.children.length > 0) {
            const foundObject = findObjectById(obj.children, id);
            if (foundObject) {
                return foundObject; // Found the object in one of the children
            }
        }
    }
    return null; // Object with the given ID not found
}

//Used for Bookmark Manager
export function extractBookmark(html) {
    //const regex = /<a\s+id="([^"]{1,})">([^<]+)<\/a>/g;
    const regex = /<a\s+id="([^"]{1,})">/g;

    const bookmark = [];
    let match;

    while ((match = regex.exec(html))) {
        bookmark.push(match[1]);
    }

    return bookmark;
}

export function deleteObjectsNotInNew(old, newA) {
    const newBookmarkIds = newA.map(item => item);
    const filtered = old.filter(item => newBookmarkIds.includes(item.bookmarkId));
    return filtered;
}

export function summarizePhrase(phrase, maxLength = 24) {
    // Remove non-alphanumeric characters and convert to lowercase
    const alphanumeric = phrase.replace(/[^a-z0-9\s]/gi, "").toLowerCase();

    // Remove all spaces
    const withoutSpaces = alphanumeric.replace(/\s/g, "");

    let summarized = "";
    let length = 0;

    // Iterate over each character in the withoutSpaces string
    for (const char of withoutSpaces) {
        // Check if adding the character exceeds the length limit
        if (length + 1 > maxLength) {
            break;
        }

        summarized += char;
        length++;
    }

    return summarized;
}

export const waitFor = (obj, interval = 100) => {
    return new Promise((resolve) => {
        const checkVariable = () => {
            if (obj) {
                resolve();
            } else {
                setTimeout(checkVariable, interval);
            }
        };
        checkVariable();
    });
}

export const filterTreeNode = (inputValue, treeNode) => {
    const label = treeNode.title;
    const regex = new RegExp(inputValue, 'i'); // 'i' flag for case-insensitive matching
    return regex.test(label);
};

//********************************
const LOCAL = '128463d7-fe03-11ed-8a9c-000c2946f232';
const LIVE  = '75cf86b6-f2e9-11ed-8a9c-000c2946f232';
//*********************************

export const SITE_ID = LIVE;

export const isLocalBuild = () => {
    return SITE_ID === LOCAL ? LOCAL : null;
}
