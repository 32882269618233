import {Drawer, Menu} from "antd";
import {AppstoreOutlined, EditOutlined, FileWordOutlined, HomeOutlined, PoweroffOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import {useAtom} from "jotai";
import {UserAtom} from "../Context/UserContext";

const MenuDrawer = (props) => {
    const navigate = useNavigate();
    const [userAtom, _] = useAtom(UserAtom);
    const getItem = (label, key, icon, children, type) => {
        return {
            key,
            icon,
            children,
            label,
            type,
        };
    }

    let items = [];

    if (userAtom.isLoggedIn) {
        items = [
            getItem('Trang chủ', '/', <HomeOutlined/>),
            userAtom.role === 1 ? getItem('Quản lý danh mục', 'category', <AppstoreOutlined/>) : null,
            userAtom.role === 1 ? getItem('Thêm bài viết', 'article/compose/new', <EditOutlined/>) : null,
            userAtom.role === 1 ? getItem('Quản lý bài viết', 'article', <FileWordOutlined/>) : null,
            {type: 'divider'},
            getItem('Thoát', 'logout', <PoweroffOutlined/>)
        ];
    }

    const onMenuClick = (e) => {
        props.setVisible(false);
        navigate(e.key !== "" ? e.key : null);
    }

    return(
    <Drawer
        title="Menu"
        placement="left"
        closable={true}
        onClose={props.handleCloseDrawer}
        open={props.drawerVisible}
        width={280}
    >
        <Menu
            onClick={onMenuClick}
            mode="inline"
            style={{
                width: '230px',
                fontWeight: '500',
                borderInlineEnd: 'none'
            }}
            items={items}>
        </Menu>
    </Drawer>
    )
}

export default MenuDrawer
