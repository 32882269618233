import { useState } from 'react';
import { Layout, Button, Image, App} from 'antd';
import { MenuOutlined, } from '@ant-design/icons';
import {Outlet, Route, Routes, useNavigate} from "react-router-dom";
import {ProtectedRoute} from "../ProtectedRoute";
import NoMatch404 from "../404";
import MenuDrawer from "../MenuDrawer";
import PostAuth from "../PostAuth";
import {LoginArea} from "./LoginArea";
import LogoutPage from "../Logout";
import Home from "../Home";
import CategoryManagement from "../Category";
import NewArticle from "../Article/Compose";
import ArticleList from "../Article/ArticleList";
import ViewArticle from "../Article/ViewArticle";
import BrowseCategory from "../Browse/CategoryBrowse";

const { Header } = Layout;
const PageContainer = () => {

    const [drawerVisible, setDrawerVisible] = useState(false);
    const navigate = useNavigate();
    const handleMenuClick = () => {
        setDrawerVisible(true);
    };
    const handleCloseDrawer = () => {
        setDrawerVisible(false);
    };

    return (
        <App>
            <Header style={{ height: 45, display: 'flex', alignItems: 'center', background: 'linear-gradient(108deg, rgba(0,0,0,1) 27%, rgba(202,33,33,1) 75%)'}}>
                <div style={{ width: 1000, margin: '0 auto', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div>
                        <Button type="text" style={{color: "white"}} icon={<MenuOutlined />} onClick={handleMenuClick} />
                        <Image preview={false} height="25px" style={{width: "auto", cursor: "pointer", paddingLeft: "10px"}} onClick={() => {navigate("/")}} src="/LOGO-FORLIFE-02-white.png" />
                    </div>
                    <div>
                        <LoginArea />
                    </div>
                </div>
            </Header>

            <MenuDrawer drawerVisible={drawerVisible} setVisible={setDrawerVisible} handleCloseDrawer={handleCloseDrawer}/>

            <Routes>
                <Route element={<Outlet />}>
                    <Route path="/" element={<ProtectedRoute><Home /></ProtectedRoute>} />
                    <Route path="/browse/:categoryId?" element={<ProtectedRoute><BrowseCategory /></ProtectedRoute>} />
                    <Route path="/category" element={<ProtectedRoute><CategoryManagement /></ProtectedRoute>} />
                    <Route path="/article/compose/:articleId" element={<ProtectedRoute><NewArticle /></ProtectedRoute>} />
                    <Route path="/article" element={<ProtectedRoute><ArticleList /></ProtectedRoute>} />
                    <Route path="/article/view/:articleId" element={<ProtectedRoute><ViewArticle /></ProtectedRoute>} />
                    <Route path="/postAuth" element={<PostAuth />} />
                    <Route path="/logout" element={<LogoutPage />} />
                </Route>
                <Route path="*" element={<NoMatch404 />} />
            </Routes>
        </App>
    );
};

export default PageContainer;
