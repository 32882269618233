import { atom } from 'jotai';
const UserAtomSchema = {
    isLoggedIn: false,
    uid: '',
    fullName: '',
    department: '',
    role: 0
}
const UserAtom = atom(UserAtomSchema);

export { UserAtom, UserAtomSchema };
