import {UserAtom, UserAtomSchema} from "../Context/UserContext";
import {useAtom} from "jotai";
import {Image} from "antd";
import {useEffect} from "react";
import {deleteJWT} from "../../Common";
const LogoutPage = () => {
    const [_, setUserAtom] = useAtom(UserAtom);

    useEffect(() => {
        deleteJWT();
        setUserAtom(UserAtomSchema);
    }, [])

    return(
        <div style={{paddingTop: "50px", textAlign: "center"}}>
            <Image src="/cat_logout.png" preview={false}/><br />
            <span className="logout-text">Good bye!</span>
        </div>
    )
}

export default LogoutPage;
