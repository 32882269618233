import React, {useEffect, useState} from "react";
import ajax from "../Utils/APICall";
import {Button, Skeleton, Space, Switch, Table, Typography, App, Input} from "antd";
import {DeleteOutlined, EditOutlined, ExclamationCircleFilled, ReloadOutlined, SearchOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import SakuraSpinner from "../Utils/SakuraSpinner";
import {get, map} from "lodash";

const {Title} = Typography;
const ArticleList = () => {
    const {modal} = App.useApp();
    const navigate = useNavigate();

    const [articleFilter, setArticleFilter] = useState({})
    const [articleList, setArticleList] = useState([]);
    const [originalArticleList, setOriginalArticleList] = useState([])

    const [categoryFilterList, setCategoryFilterList] = useState([])
    const [searchText, setSearchText] = useState('');

    const [dataLoading, setDataLoading] = useState(false)

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        setDataLoading(true)
        ajax.post('/article/list', articleFilter).then(resp => {
            if (typeof resp !== 'object')
                return;

            setArticleList(resp);
            setOriginalArticleList(resp);

            const articleCategoryList = resp.reduce((result, obj) => {
                const categoryName = obj.categoryName;
                const existingCategoryIndex = result.findIndex((item) => item.text === categoryName);

                if (existingCategoryIndex !== -1) {
                    result[existingCategoryIndex].count++;
                } else {
                    result.push({ text: obj.categoryName, value: obj.categoryName, count: 1 });
                }
                return result;
            }, []);

            setCategoryFilterList(articleCategoryList.map(item => ({...item, text: `${item.text} (${item.count})`})))

        }).finally(() => setDataLoading(false));
    }

    const setPublishStatus = (articleId, status) => {
        ajax.post('/article/setPublish', {id: articleId, status: status});
    }

    const deleteArticle = (articleId) => {
        modal.confirm({
            title: 'Xóa bài viết',
            icon: <ExclamationCircleFilled />,
            content: 'Có chắc chắn muốn xóa bài viết này?',
            okText: 'Đồng ý',
            okType: 'danger',
            cancelText: 'Bỏ qua',
            onOk() {
                return new Promise((resolve, reject) => {
                    ajax.post('/article/delete', {id: articleId}).then(resp => {
                        resolve()
                        if (resp) {
                            setArticleList(articleList.filter(obj => obj._id !== resp._id))
                            resolve()
                        }
                    }).finally(() => {
                        resolve();
                    })
                }).catch(() => {}
                );
            },
            onCancel() {
            },
        });
    }

    const editArticle = (articleId) => {
        navigate(`/article/compose/${articleId}`);
    }

    const onSearch = e => {
        const reg = new RegExp(e.target.value, "gi");
        const filteredData = map(originalArticleList, record => {
            const articleTitle = get(record, "title").match(reg);
            if (!articleTitle) {
                return null;
            }
            return record;
        }).filter(record => !!record);

        setArticleList(filteredData);
    }

    const columns = [
        {
            title: 'Tiêu đề',
            dataIndex: 'title',
            key: '_id',
        },
        {
            title: 'Danh mục',
            dataIndex: 'categoryName',
            key: '',
            filters : categoryFilterList,
            filterMode: 'tree',
            filterSearch: true,
            onFilter: (value, record) => record.categoryName.startsWith(value),
        },
        {
            title: 'Xem',
            dataIndex: 'viewCount',
            key: '',
        },
        {
            title: 'Hiển thị',
            dataIndex: '',
            key: '',
            render: (row) => <Switch defaultChecked={row.isPublished} onChange={(checked) => setPublishStatus(row._id, checked)} size="small"/>
        },
        {
            title: '',
            dataIndex: '',
            key: '',
            align: 'end',
            render: (row) => {
                return (
                    <Space>
                        <Button title="Sửa bài viết" icon={<EditOutlined />} onClick={() => editArticle(row._id)}></Button>
                        <Button title="Xóa bài viết" icon={<DeleteOutlined />} onClick={() => deleteArticle(row._id)}></Button>
                    </Space>
                )
            }
        }
    ]

    return(
        !articleList.length === 0 ? (<Skeleton active />) :
            (
                <div>
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'baseline'}}>
                        <Title level={4}>Danh mục bài viết</Title>
                        <Space>
                            <Input
                                prefix={<SearchOutlined />}
                                onChange={onSearch}
                                allowClear
                                placeholder="Tìm kiếm theo tên bài viết"
                                style={{height: '34px'}}
                            />
                            <Button title="Tạo bài viết mới" danger icon={<EditOutlined />} onClick={() => navigate('/article/compose/new')} />
                            <Button type="primary" title="Làm mới dữ liệu" ghost onClick={loadData} icon={<ReloadOutlined />}></Button>
                        </Space>
                    </div>
                    <Table
                        dataSource={articleList}
                        columns={columns}
                        pagination={{position: ['bottomCenter']}}
                    />
                    <SakuraSpinner open={dataLoading}/>
                </div>
            )
    )
}
export default ArticleList
