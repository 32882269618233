import {Button, Card, Modal, Space, Table, Typography} from "antd";
import React, {useEffect, useState} from "react";
import EditCategory from "./EditCategory";
import ajax from "../Utils/APICall";
import {isObjectOrArray, findObjectById} from "../../Common";
import {DeleteOutlined, EditOutlined, ReloadOutlined} from "@ant-design/icons";
import {notify} from "../Utils/Dialog";
import SakuraSpinner from "../Utils/SakuraSpinner";
const CategoryManagement = () => {
    const [editBoxOpen, setEditBoxOpen] = useState(false);
    const [categoryList, setCategoryList] = useState([]);
    const [categoryEditData, setCategoryEditData] = useState(null);

    const [confirmOpen, setConfirmOpen] = useState(false);
    const [idToDelete, setIdToDelete] = useState();
    const [deleteButtonLoading, setDeleteButtonLoading] = useState(false);

    const [dataLoading, setDataLoading] = useState(false)

    useEffect(() => {
        loadData();
    }, [])

    const loadData = () => {
        setCategoryList([]);
        setDataLoading(true);
        ajax.post("/category/get").then(resp => {
            if (isObjectOrArray(resp))
                setCategoryList(resp);
        }).finally(() => setDataLoading(false));
    }
    const addNewButton = () => {
        setEditBoxOpen(true);
    }

    const editCategory = (objectId) => {
        setCategoryEditData(findObjectById(categoryList, objectId));
        setEditBoxOpen(true);
    }
    const closeDrawer = () => {
        setEditBoxOpen(false);
        setCategoryEditData(null);
    };

    const confirmDelete = (id) => {
        setIdToDelete(id);
        setConfirmOpen(true);
    }

    const deleteCategory = () => {
        if (idToDelete){
            setDeleteButtonLoading(true);
            ajax.post("/category/delete", {categoryId: idToDelete}).then(resp => {
                if (resp)
                    notify("success", resp);
            }).finally(() => {
                setDeleteButtonLoading(false);
                setConfirmOpen(false);
                loadData();
            })
        } else {
            notify("error", "Không tìm thấy dữ liệu để xóa");
        }
    }

    const columns = [
        {
            title: 'Tên danh mục',
            dataIndex: 'categoryName',
            key: '_id',
            width: '60%',
            render: (_, item) => (
                <span style={{cursor: item.numChildren > 0 ? 'pointer' : 'default'}}>
                {item.categoryName}
                </span>
            ),
        },
        {
            title: 'Mục con',
            dataIndex: 'numChildren',
            key: '_id',
            align: 'center',
            width: '15%'
        },
        {
            title: '',
            key: 'address',
            width: '25%',
            render: (_, item) => {
                return(
                    <Space style={{display: "flex", justifyContent: "end"}}>
                        <Button type="primary" onClick={() => editCategory(item._id)} icon={<EditOutlined />}> Sửa</Button>
                        <Button icon={<DeleteOutlined/>} onClick={() => confirmDelete(item._id)} disabled={item.numChildren > 0 || item.level === 0 ? true : false}/>
                    </Space>
                )
            }
        },
    ];

    return(
        <div>

            <Card title="Quản lý danh mục" extra={<Space><Button type="primary" ghost onClick={addNewButton}>+ Thêm mới</Button><Button type="primary" title="Làm mới dữ liệu" ghost onClick={loadData} icon={<ReloadOutlined />}></Button></Space>}>
                <Table
                    columns={columns}
                    dataSource={categoryList}
                    defaultExpandAllRows={false}
                    expandable={{ expandRowByClick: true }}
                />
            </Card>

            <EditCategory open={editBoxOpen} close={closeDrawer} categoryEditData={categoryEditData} refresh={loadData}/>
            <Modal
                title="Xác nhận xóa"
                open={confirmOpen}
                onOk={deleteCategory}
                okButtonProps={{disabled: deleteButtonLoading, loading: deleteButtonLoading}}
                onCancel={() => {setIdToDelete(null); setConfirmOpen(false)}}
                okText="Đồng ý"
                cancelText="Bỏ qua"
            >
                <p>Có chắc chắn muốn xóa mục này không?</p>
            </Modal>
            <SakuraSpinner open={dataLoading}/>
        </div>
    )
}
export default CategoryManagement
