import { PlusOutlined } from '@ant-design/icons';
import { Input, Space, Tag, Tooltip, theme } from 'antd';
import {forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState} from 'react';
const ArticleTags = forwardRef((props, ref) => {

    useImperativeHandle(ref, () => ({
        getTags() {
            return tags;
        }
    }));

    const { token } = theme.useToken();
    const [tags, setTags] = useState([]);
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [editInputIndex, setEditInputIndex] = useState(-1);
    const [editInputValue, setEditInputValue] = useState('');
    const inputRef = useRef(null);
    const editInputRef = useRef(null);
    const {initialTags} = props;

    const tagColors = ["magenta", "red", "volcano", "orange", "gold", "lime", "green", "cyan", "blue", "geekblue", "purple"];

    function getRandomTagColor() {
        const randomIndex = Math.floor(Math.random() * tagColors.length);
        return tagColors[randomIndex];
    }

    useEffect(() => {
        if (inputVisible) {
            inputRef.current?.focus();
        }
    }, [inputVisible]);

    useEffect(() => {
        editInputRef.current?.focus();
    }, [inputValue]);

    useMemo( () => {
        if (initialTags)
            setTags(initialTags.map(tag => {return {tagName: tag, tagColor: getRandomTagColor()}}));

    }, [initialTags]);
    const handleClose = (removedTag) => {
        const newTags = tags.filter((tag) => tag.tagName !== removedTag);
        setTags(newTags);
    };

    const showInput = () => {
        setInputVisible(true);
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleInputConfirm = () => {
        if (inputValue && !tags.find((tag) => tag.tagName === inputValue)) {
            const newTag = {
                tagName: inputValue,
                tagColor: getRandomTagColor()
            };
            setTags([...tags, newTag]);
        }
        setInputVisible(false);
        setInputValue('');
    };

    const handleEditInputChange = (e) => {
        setEditInputValue(e.target.value);
    };

    const handleEditInputConfirm = () => {
        const newTags = [...tags];
        newTags[editInputIndex].tagName = editInputValue;
        setTags(newTags);
        setEditInputIndex(-1);
        setInputValue('');
    };

    const tagInputStyle = {
        width: 78,
        verticalAlign: 'top',
    };

    const tagPlusStyle = {
        background: token.colorBgContainer,
        borderStyle: 'dashed',
    };

    return (
        <Space size={[0, 8]} wrap>
            <Space size={[0, 8]} wrap>
                {tags.map((tag, index) => {
                    if (editInputIndex === index) {
                        return (
                            <Input
                                ref={editInputRef}
                                key={tag.tagName}
                                size="small"
                                style={tagInputStyle}
                                value={editInputValue}
                                onChange={handleEditInputChange}
                                onBlur={handleEditInputConfirm}
                                onPressEnter={handleEditInputConfirm}
                            />
                        );
                    }
                    const isLongTag = tag.tagName.length > 20;
                    const tagElem = (
                        <Tag
                            key={tag.tagName}
                            color={tag.tagColor}
                            closable={true}
                            style={{
                                userSelect: 'none',
                            }}
                            onClose={() => handleClose(tag.tagName)}
                        >
              <span
                  onDoubleClick={(e) => {
                      setEditInputIndex(index);
                      setEditInputValue(tag.tagName);
                      e.preventDefault();
                  }}
              >
                {isLongTag ? `${tag.tagName.slice(0, 20)}...` : tag.tagName}
              </span>
                        </Tag>
                    );
                    return isLongTag ? (
                        <Tooltip title={tag.tagName} key={tag.tagName}>
                            {tagElem}
                        </Tooltip>
                    ) : (
                        tagElem
                    );
                })}
            </Space>
            {inputVisible ? (
                <Input
                    ref={inputRef}
                    type="text"
                    size="small"
                    style={tagInputStyle}
                    value={inputValue}
                    onChange={handleInputChange}
                    onBlur={handleInputConfirm}
                    onPressEnter={handleInputConfirm}
                />
            ) : (
                <Tag style={tagPlusStyle} onClick={showInput}>
                    <PlusOutlined /> New Tag
                </Tag>
            )}
        </Space>
    );
});
export default ArticleTags;
