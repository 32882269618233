import {Button, Input, List, Modal, Space} from "antd";
import React, {useEffect, useState} from "react";
import {notify} from "../Utils/Dialog";
import {summarizePhrase} from "../../Common";
const BookmarkManager = (props) => {
    const {open, close, bookmarkList, goToBookmark} = props;
    return (
        <Modal
            title="Danh mục Bookmark"
            open={open}
            onOk={() => {close()}}
            onCancel={() => {close()}}
            closable={true}
        >
            <List
                itemLayout="horizontal"
                dataSource={bookmarkList}
                renderItem={(item, index) => (
                    <List.Item>
                        <List.Item.Meta
                            title={item.bookmarkTitle}
                            description={`#${item.bookmarkId}`}
                        />
                        <Button onClick={() => goToBookmark(item.bookmarkId)}>Đi tới</Button>
                    </List.Item>
                )}
            />
        </Modal>
    )
}

const BookmarkModalInput = (props) => {
    const noValue = {bookmarkId: "", bookmarkTitle: ""};
    const [inputValue, setInputValue] = useState(noValue);
    const {open, close, onOK, onCancel, bookmarkList, selected} = props;

    useEffect(() => {
        if (selected && selected.length > 0){ //Có bookmark đang được select trong editor
            const bookmark = bookmarkList.find((val) => val.bookmarkId === selected);
            setInputValue(bookmark ? bookmark : noValue);
        }
    }, [selected]);



    const handleOk = () => {
        if ((inputValue.bookmarkId.trim() == "") || (inputValue.bookmarkTitle.trim() == "")){
            notify("error", "Vui lòng nhập thông tin bookmark")
            return;
        }
        let result = {
            mode: selected ? "edit" : "new",
            data: inputValue,
            previous: selected
        }
        setInputValue(noValue);
        onOK(result);

        close();
    };

    const handleCancel = () => {
        setInputValue(noValue);
        onCancel(null);
        close();
    };
    const handleInputTitleChange = (e) => {
        let title = e.target.value.substring(0,128);
        setInputValue({bookmarkId: `bookmark_${summarizePhrase(title)}`, bookmarkTitle: title});
    }

    return (
        <div>
            <Modal
                title="Nhập bookmark"
                open={open}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <Space
                    direction="vertical"
                    style={{width: '100%'}}
                >
                    <Input
                        value={inputValue.bookmarkTitle}
                        onChange={handleInputTitleChange}
                        placeholder="Nhập tiêu đề cho bookmark"
                    />
                    <Input
                        addonBefore="#"
                        value={inputValue.bookmarkId}
                        disabled={true}
                    />
                </Space>
            </Modal>
        </div>
    );

};

export {BookmarkManager, BookmarkModalInput};
