import './App.css';
import { ConfigProvider } from 'antd';
import PageContainer from "./Components/MainContainer";
import {getJWT} from "./Common";
import {UserAtom} from "./Components/Context/UserContext";
import {useAtom} from "jotai";
import {useEffect} from "react";
import {Toaster} from "react-hot-toast";
function App() {

    const [_, setUserAtom] = useAtom(UserAtom);

    useEffect(() => {
        const jwt = getJWT();

        if (jwt){
            setUserAtom({
                isLoggedIn: true,
                fullName: jwt.name,
                uid: jwt.uid,
                department: jwt.dept,
                role: jwt.role
            })
        }
    }, []);

    return (
        <ConfigProvider
            theme={{
                token: {
                    screenLGMin: 1024,
                    screenLG: 1024,
                    borderRadius: '12px',
                    colorPrimary: '#C32126',
                    //fontFamily: "'SVN-Gilroy', sans-serif",
                    //fontWeightStrong: 500
                },
            }}
        >
            <div><Toaster /></div>
            <div className="App">
                <PageContainer />
            </div>
        </ConfigProvider>
    );
}

export default App;
