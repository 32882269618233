import {UserAtom} from "../Context/UserContext";
import {useAtom} from "jotai";
import {UserOutlined} from "@ant-design/icons";
import {Button} from "antd";
import {useState} from "react";
import {SITE_ID} from "../../Common";

export const LoginArea = () => {
    const [userAtom] = useAtom(UserAtom);
    const [loginLoadingStatus, setLoginLoadingStatus] = useState(false);
    const toLogin = () => {
        setLoginLoadingStatus(true);
        window.location.href = 'https://login.forlife.vn/?site=' + SITE_ID;
    }

    if (!userAtom.isLoggedIn){
        return(
            <Button loading={loginLoadingStatus} style={{color: "#961B20", fontWeight: "500"}} onClick={toLogin} icon={<UserOutlined />}>
                Đăng nhập
            </Button>
        )
    } else {
        return(
            <span style={{color: "white"}}>
                <UserOutlined /> {userAtom.fullName}
            </span>
        )
    }

}
