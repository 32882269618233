import React, {useMemo, useRef} from 'react';
import { Editor } from '@tinymce/tinymce-react';
import {Badge, Button, Card, Col, Input, Modal, Row, Space, Switch, TreeSelect} from "antd";
import {useEffect, useState} from "react";
import ajax from "../Utils/APICall";
import ArticleTags from "./Tags";
import {BookmarkModalInput, BookmarkManager} from "./Bookmark";
import {deleteObjectsNotInNew, extractBookmark, filterTreeNode, getJWT} from "../../Common";
import {IconBookmarkPlus, IconBookmarks} from "../../Common/Icons";
import {notify} from "../Utils/Dialog";
import {useParams, useNavigate} from "react-router-dom";
import {BarsOutlined, CodeOutlined} from "@ant-design/icons";
import SakuraSpinner from "../Utils/SakuraSpinner";

const NewArticle = () => {
    const {articleId} = useParams();
    const navigate = useNavigate();

    const [category, setCategory] = useState([]);
    const titleRef = useRef(null);

    const [articleTitle, setArticleTitle] = useState('');
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [isPublish, setIsPublish] = useState(false);

    const [submitLoading, setSubmitLoading] = useState(false);
    const [editorLoading, setEditorLoading] = useState(true);

    const [htmlCodeOpen, setHtmlCodeOpen] = useState(false);
    const [htmlContent, setHtmlContent] = useState(null);

    const [loadedEditorData, setLoadedEditorData] = useState({})

    const tagRef = useRef(null);

    const [modalInputState, setModalInputOpen] = useState(false);

    const [bookmarkManagerOpen, setBookmarkManagerOpen] = useState(false);
    const [bookmarkList, setBookmarkList] = useState([]);
    const [bookmarkSelect, setBookmarkSelect] = useState(null);

    const editorRef = useRef(null);

    useEffect(() => {
        ajax.post("/category/get", {type: 'select'}).then(resp => {
            setCategory(resp);
        }).then(() => {
            if (articleId && (articleId !== 'new') && (articleId.length == 24))
                ajax.post('/article/get', {id: articleId}).then((resp) => {
                    if (!resp){
                        navigate('/article')
                    }

                    if (typeof resp == 'object'){
                        setLoadedEditorData(resp);
                    }
                })
        });
    }, []);

    useEffect(() => {
        const { rawContent} = loadedEditorData;

        const checkEditorRef = () => {
            return new Promise((resolve) => {
                const checkVariable = () => {
                    if (editorRef.current) {
                        resolve();
                    } else {
                        setTimeout(checkVariable, 100);
                    }
                };
                checkVariable();
            });
        }

        if (rawContent) {
            checkEditorRef().then(() => {
                editorRef.current.setContent(rawContent, {format: 'raw'});
            })
        }
    }, [loadedEditorData])

    useMemo(() => {
        const {title, category, bookmarks, isPublished} = loadedEditorData;

        if (category) {
            setSelectedCategory(category);
        }

        if (title){
            setArticleTitle(title);
        }

        if (bookmarks){
            setBookmarkList(bookmarks)
        }

        if (isPublished){
            setIsPublish(isPublished);
        }

    }, [loadedEditorData])


    //ModalInput function*********
    const modalInputClose = () => {
        setModalInputOpen(false);
        setBookmarkSelect(null);
    }

    //****************************

    //Bookmark variables

    const manageBookmark = () => {
        cleanOrphanBookmark();
        setBookmarkManagerOpen(true)
    }
    const closeBookmarkManager = () => {
        setBookmarkManagerOpen(false);
    }

    const cleanOrphanBookmark = () => {
        let filteredBookmarks;

        try {
            // Clean orphan bookmark here
            if (editorRef.current) {
                const content = editorRef.current.getContent();
                const bookmark = extractBookmark(content);
                filteredBookmarks = deleteObjectsNotInNew(bookmarkList, bookmark)
                setBookmarkList(filteredBookmarks);
            }

            if (bookmarkSelect) {
                if (!bookmarkList.find((item) => item.bookmarkId === bookmarkSelect.bookmarkId)) {
                    setBookmarkSelect(null);
                }
            }

            return filteredBookmarks;
        } catch (error) {
            // Handle any errors that occurred during the asynchronous operations
            console.error(error);
        }
    };
    const insertBookmark = () => {
        if (editorRef.current) {
            cleanOrphanBookmark();
            setModalInputOpen(true);
        }
    }

    const insertBookmarkDOM = (value) => {
        if (editorRef.current)
            editorRef.current.execCommand('InsertHTML', false, `<a id="${value.data.bookmarkId}" contenteditable="false" class="mce-item-anchor"></a>&#xFEFF;`);
    }
    const insertBookmarkOK = (value) => {
        setModalInputOpen(false);

        if (editorRef.current) {
            //let bookmark = extractBookmark(editorRef.current.getContent()); //Lấy tất cả bookmark hiện có

            if (value.mode === 'new') {
                const bookmarkExists = bookmarkList.some(val => val.bookmarkId === value.data.bookmarkId);
                if (bookmarkExists) {
                    notify("error", `Bookmark [${value.data.bookmarkId}] đã tồn tại. Vui lòng kiểm tra lại`);
                    return;
                }
                setBookmarkList([...bookmarkList, value.data]);
                insertBookmarkDOM(value);
                setBookmarkSelect(null);
            } else if (value.mode === 'edit') {
                setBookmarkList((bookmarkList) =>
                    bookmarkList.map((bookmark) => {
                        if (bookmark.bookmarkId === value.previous) {
                            if (editorRef.current) {
                                const element = editorRef.current.dom.get(value.previous);
                                if (element) {
                                    editorRef.current.dom.replace(
                                        editorRef.current.dom.createFragment(`<a id="${value.data.bookmarkId}" contenteditable="false" class="mce-item-anchor"></a>&#xFEFF;`),
                                        element
                                    );
                                }
                            }
                            return { ...bookmark, bookmarkTitle: value.data.bookmarkTitle, bookmarkId: value.data.bookmarkId };
                        }
                        return bookmark;
                    })
                );

                setBookmarkSelect(null);
            }

        }
    }

    function parseHTML(htmlString) {
        const doc = new DOMParser().parseFromString(htmlString, 'text/html');
        const imgTags = doc.getElementsByTagName('img');

        for (const imgTag of imgTags) {
            const width = parseInt(imgTag.getAttribute('width'), 10);
            const height = parseInt(imgTag.getAttribute('height'), 10);
            if (!isNaN(width) && !isNaN(height)) {
                const ratio = width / height;
                const newWidth = width > 600 ? 600 : width;
                const newHeight = Math.round(newWidth / ratio);
                imgTag.setAttribute('width', String(newWidth));
                imgTag.setAttribute('height', String(newHeight));
            } else if (!imgTag.getAttribute('width')) {
                imgTag.setAttribute('width', '600');
            }

            imgTag.removeAttribute('data-mce-src');
        }

        const bodyContent = Array.from(doc.body.childNodes)
            .map((node) => node.outerHTML)
            .join('');

        return bodyContent;
    }
    const getArticleData = () => {
        return {
            title: titleRef.current.input.value.trim(),
            category: selectedCategory,
            tags: tagRef.current ? tagRef.current.getTags().map(tag => tag.tagName) : [],
            bookmarks: cleanOrphanBookmark(),
            wordCount: editorRef.current ? editorRef.current.plugins.wordcount.body.getWordCount() : 0,
            charCount: editorRef.current ? editorRef.current.plugins.wordcount.body.getCharacterCount() : 0,
            isPublished: isPublish,
            rawContent: editorRef.current ? parseHTML(editorRef.current.getContent({ format: 'raw' })) : '',
            viewContent: editorRef.current ? parseHTML(editorRef.current.getContent()) : '',
            indexContent: editorRef.current ? editorRef.current.getContent({ format: 'text' }) : ''
        }
    }
    const goToBookmark = (element) => {
        if (!element)
            return;

        if (editorRef.current){
            let targetElement = editorRef.current.dom.get(element);
            let range = editorRef.current.dom.createRng();
            range.selectNode(targetElement);
            editorRef.current.selection.setRng(range);
            targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
            setBookmarkManagerOpen(false);
        }
    }
    //***********************

    const save = () => {
        const jwt = getJWT();

        if (!jwt) {
            notify("error", "Phiên làm việc không hợp lệ. Vui lòng đăng nhập lại.")
            return false;
        }

        if (titleRef.current){
            let value = titleRef.current.input.value.trim();
            if (value && value.length >= 6) {

            } else {
                notify("error", "Vui lòng nhập tiêu đề cho bài viết (tối thiểu 6 ký tự)");
                titleRef.current.focus();
                return;
            }
        } else {
            return;
        }

        if (!selectedCategory || (selectedCategory === '')){
            notify("error", "Vui lòng chọn danh mục cho bài viết");
            return
        }

        //Build data to send to server
        const payload = getArticleData();

        if (loadedEditorData){ //Dữ liệu được load -> Đã có dữ liệu
            payload.editTarget = loadedEditorData._id;
        }

        setSubmitLoading(true);

        ajax.post("/article/add", payload).then(resp => {
            if (resp && (resp === "OK")){
                navigate('/article');
            }
        }).finally(() => {
            setSubmitLoading(false);
        })
    }

//**********************

    const example_image_upload_handler = (blobInfo, progress) => new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append('file', blobInfo.blob(), blobInfo.filename());

        ajax.post('/image/upload', formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        }).then(resp => {
            console.log(resp);
            if (resp)
                resolve(resp);
            else
                reject("Có lỗi xảy ra khi xử lý ảnh.");
        });
    });

    const TinyMCE = (props) => {
        return (
            <Editor
                apiKey='qkkifx03m62waie0u7mu0z36fvh4zzv91r15me65jg1hisfv'
                onInit={(evt, editor) => editorRef.current = editor}
                initialValue=""
                init={{
                    height: 500,
                    width: '100%',
                    menubar: false,
                    plugins: [
                        'advlist', 'autolink', 'autosave', 'lists', 'link', 'image', 'media', 'charmap', 'preview',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'code', 'wordcount', 'preview'
                    ],
                    toolbar: 'blocks | ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | link | image | media | preview',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    images_upload_handler: example_image_upload_handler,
                    setup: (editor) => {
                        editor.on('init', () => {
                            setEditorLoading(false);
                        })
                        editor.on('NodeChange', (event) => {
                            let {element} = event;

                            if ((element.tagName === 'A') && (element.className === 'mce-item-anchor') && (element.attributes.hasOwnProperty('id')) && (element.attributes.id.value !== "")){
                                setBookmarkSelect(element.attributes.id.value);
                            } else {
                                setBookmarkSelect(null);
                            }
                        })
                        editor.on('keydown', (event) => {
                            // Check if the pressed key is the Tab key (key code 9)
                            if (event.keyCode === 9) {
                                // Prevent the default tab behavior
                                event.preventDefault();

                                // Insert the tab character manually at the current cursor position
                                editor.execCommand('mceInsertContent', false, '&emsp;&emsp;');
                            }
                        })
                    }
                }}
            />
        )
    }

    const MemoizedTiniMCE = useMemo(() => <TinyMCE currentBookmarkList={bookmarkList}/>, []);

    return (
        <div>
            <Card
                title="Thông tin bài viết"
                extra={<Button title="Quay lại trang danh sách bài viết" icon={<BarsOutlined />} onClick={() => {navigate('/article')}} />}
            >
                <Row>
                    <Col span={6}>
                <span>
                    Tiêu đề
                </span>
                    </Col>
                    <Col span={18}>
                        <Input type="text" ref={titleRef} value={articleTitle} onChange={(e) => setArticleTitle(e.target.value)}/>
                    </Col>
                </Row>
                <Row style={{marginTop: '10px'}}>
                    <Col span={6}>
                <span>
                    Danh mục
                </span>
                    </Col>
                    <Col span={18}>
                        <TreeSelect
                            value={selectedCategory}
                            onClick={()=>{console.log("Select click")}}
                            onChange={(change) => setSelectedCategory(change)}
                            style={{width: '100%'}}
                            showSearch
                            allowClear
                            filterTreeNode={filterTreeNode}
                            treeDefaultExpandAll={true}
                            treeData={category}
                            dropdownStyle={{
                                maxHeight: 300,
                                width: '100%',
                                overflow: 'auto',
                            }}
                        />
                    </Col>
                </Row>
                <Row style={{marginTop: '10px'}}>
                    <Col span={6}>
                <span>
                    Tags
                </span>
                    </Col>
                    <Col span={14}>
                        <ArticleTags ref={tagRef} initialTags={loadedEditorData?.tags}/>
                    </Col>
                    <Col span={4} style={{textAlign: "right"}}>
                        <Space>
                            <Button type={bookmarkSelect !== null ? "primary" : "dashed"} icon={<IconBookmarkPlus/>} onClick={insertBookmark}></Button>
                            <Badge count={bookmarkList.length}>
                                <Button icon={<IconBookmarks/>} onClick={manageBookmark}></Button>
                            </Badge>
                        </Space>
                    </Col>
                </Row>
                <Row style={{marginTop: '10px'}}>
                    {MemoizedTiniMCE}
                    <div style={{marginTop: '10px', display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center'}}>
                        <span><Switch checked={isPublish} onChange={setIsPublish}/> Xuất bản bài viết</span>
                        <Space>
                            <Button type="primary" onClick={() => {setHtmlCodeOpen(true); setHtmlContent(editorRef.current.getContent())}} icon={<CodeOutlined />}>HTML Source</Button>
                            <Button type="primary" onClick={save} loading={submitLoading} disabled={submitLoading}>Lưu bài viết</Button>
                        </Space>
                    </div>
                </Row>
            </Card>
            <BookmarkManager open={bookmarkManagerOpen} close={closeBookmarkManager} bookmarkList={bookmarkList} goToBookmark={goToBookmark}/>
            <BookmarkModalInput open={modalInputState} close={modalInputClose} bookmarkList={bookmarkList} selected={bookmarkSelect} onOK={insertBookmarkOK} onCancel={() => {setBookmarkSelect(null)}}/>
            <Modal
                title="Xem HTML Code"
                centered
                open={htmlCodeOpen}
                onOk={() => setHtmlCodeOpen(true)}
                onCancel={() => setHtmlCodeOpen(false)}
                style={{maxHeight: '400px'}}
            >
                <div style={{maxHeight: '300px', overflow: 'auto'}}>
                    <code>{htmlContent}</code>
                </div>
            </Modal>

            <SakuraSpinner open={editorLoading}/>
        </div>
    )
}
export default NewArticle
