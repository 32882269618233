import {Button, Drawer, Form, Input, Select, Skeleton, Space, TreeSelect} from "antd";
import {useEffect, useState} from "react";
import ajax from "../Utils/APICall";
import {isObjectOrArray} from "../../Common";
import {notify} from "../Utils/Dialog";
import TextArea from "antd/es/input/TextArea";
import {filterTreeNode} from "../../Common";

const setNotSelectableById = (data, value) => {
    return data.map((item) => {
        if (item.value === value) {
            return { ...item, selectable: false, disabled: true};
        } else if (item.children) {
            return { ...item, children: setNotSelectableById(item.children, value) };
        }
        return item;
    });
}
const EditCategory = (props) => {
    const [title, setTitle] = useState("");
    const [dataLoading, setDataLoading] = useState(true);
    const [mainCategoryList, setMainCategoryList] = useState([]);
    const [submitLoading, setSubmitLoading] = useState(false);

    const [editCategoryLevel, setEditCategoryLevel] = useState(0);

    const [form] = Form.useForm();

    useEffect(() => {
        setTitle(!props.categoryEditData ? "Thêm " : "Sửa ");
        setEditCategoryLevel(props?.categoryEditData?.level);

        if (props.open) { //Lấy danh sách danh mục cấp 0

            ajax.post("/category/get/main").then((resp) => {
                if (!isObjectOrArray(resp) || (resp.length === 0)){
                    notify("error", "Không tìm thấy danh sách danh mục chính.");
                    return;
                }

                setMainCategoryList(setNotSelectableById(resp, props?.categoryEditData?._id));

                if (props.categoryEditData) {
                    form.setFieldsValue({
                        mainCategory        : props.categoryEditData.parent,
                        categoryName        : props.categoryEditData.categoryName,
                        categoryDescription : props.categoryEditData.categoryDescription,
                        selfId              : props.categoryEditData._id
                    });
                }

                setDataLoading(false);
            })
        }
    }, [props]);

    const drawerClose = () => {
        if (!dataLoading)
            form.resetFields();

        props.close();
    }

    const onFinish = (values) => {
        setSubmitLoading(true);
        let ajaxData = {};

        if (props.categoryEditData){ //Edit Mode
            ajaxData = {
                payload: {
                    categoryId: values.selfId,
                    set: {
                        categoryName: values.categoryName,
                        categoryDescription: values.categoryDescription,
                        parent: values.mainCategory
                    }
                },
                endPoint: "/category/edit"
            }
        } else { //Add new mode
            ajaxData = {
                payload: {
                    ...values
                },
                endPoint: "/category/add"
            }
        }

        ajax.post(ajaxData.endPoint, ajaxData.payload).then(resp => {
            if (resp){
                notify("success", resp);
                drawerClose();
                props.refresh?.();
            }
        }).finally(() => {
            setSubmitLoading(false);
        })
    };

    const onFinishFailed = (errorInfo) => {
        //console.log('Form validation failed:', errorInfo);
    };

    return (
        <Drawer title={title + "danh mục"} placement="right" onClose={drawerClose} open={props.open}>
            {dataLoading ?
                (<Skeleton active />
                ) : (
                    <Form
                        form={form}
                        name="myForm"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        layout="vertical"
                    >
                        <Form.Item
                            label="Danh mục chủ"
                            name="mainCategory"

                            rules={[{ required: !(editCategoryLevel === 0), message: 'Vui lòng chọn danh mục gốc.' }]}
                        >
                            <TreeSelect
                                disabled={editCategoryLevel === 0}
                                treeData={mainCategoryList}
                                showSearch
                                filterTreeNode={filterTreeNode}
                                treeDefaultExpandAll={true}
                                dropdownStyle={{
                                    maxHeight: 300,
                                    width: '100%',
                                    overflow: 'auto',
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Tên danh mục"
                            name="categoryName"
                            rules={[
                                { required: true, message: 'Vui lòng nhâp tên danh mục.' },
                                { max: 128, min: 3, message: 'Tên danh mục quá dài hoặc quá ngắn.' },
                            ]}
                        >
                            <Input rows={4} />
                        </Form.Item>
                        <Form.Item
                            label="Mô tả danh mục"
                            name="categoryDescription"
                            rules={[
                                { required: (editCategoryLevel < 1) ? true : false, message: 'Vui lòng nhâp mô tả danh mục.' },
                                { max: 1024, min: 3, message: 'Mô tả danh mục quá dài hoặc quá ngắn.' },
                            ]}
                        >
                            <TextArea rows={3} disabled={(editCategoryLevel > 1) ? true : false} placeholder="Mô tả ngắn ngọn về danh mục này" />
                        </Form.Item>
                        <Form.Item
                            name="selfId"
                            style={{display: "none"}}
                        >
                            <Input type="hidden" />
                        </Form.Item>
                        <Form.Item style={{paddingTop: "10px"}}>
                            <Space wrap>
                                <Button type="primary" htmlType="submit" loading={submitLoading}>
                                    {title}
                                </Button>
                                <Button htmlType="button" onClick={drawerClose}>
                                    Bỏ qua
                                </Button>
                            </Space>
                        </Form.Item>
                    </Form>
                )}
        </Drawer>
    )
}

export default EditCategory
