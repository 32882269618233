import SakuraSpinner from "../Utils/SakuraSpinner";
import {useEffect, useState} from "react";
import axios from "axios";
import {isLocalBuild, setJWT} from "../../Common";
import {UserAtom} from "../Context/UserContext";
import {useAtom} from "jotai";
import {useNavigate} from "react-router-dom";
import {notify} from "../Utils/Dialog";

const PostAuth = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [_, setUserAtom] = useAtom(UserAtom);

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const token = searchParams.get('token');

        if (!token){
            setLoading(false);
            notify("error", "Không tìm thấy dữ liệu", 60);

            return;
        }

        axios.post("https://helpdesk.forlife.vn/api/postAuth", {token: token, siteId: isLocalBuild()}).then((resp => {
            /*
            console.log(resp);
            if (resp.error === 1){
                toast("error", resp.message, 60);
                return false;
            }
             */

            let user = setJWT(resp);
            if (!user){ //Lỗi JWT không decode được
                notify("error", "Invalid Token Data", 60);
                return false;
            }

            setUserAtom({
                uid: user.uid,
                fullName: user.name,
                department: user.dept,
                role: user.role,
                isLoggedIn: true
            });

            try {
                const redirect = JSON.parse(window.localStorage.getItem('redirect'));
                window.localStorage.removeItem('redirect');

                if ((Date.now() - redirect?.time) < (15*1000)){ //redirect expired
                    navigate('/article/view/' + redirect?.articleId);
                } else {
                    navigate("/");
                }
            } catch (e){
                //Do nothing
            }




        })).catch(e => {
            notify("error", e.message, 60);
        }).finally(() => {
            setLoading(false);
        })

    }, []);

    return(
        <SakuraSpinner open={loading}/>
    )
}

export default PostAuth;
