import React from 'react';
import {List, Space} from "antd";
import {LikeOutlined, StarOutlined} from "@ant-design/icons";
const IconText = ({ icon, text }) => (
    <Space>
        {React.createElement(icon)}
        {text}
    </Space>
);
const SearchResult = (props) => {
    const {data, searchResultVisible} = props;
    const parseStringWithHighlight = (str) => {
        const highlightRegex = /<highlight>(.*?)<\/highlight>/g;
        const matches = str.match(highlightRegex);

        if (!matches) {
            return str;
        }

        const parsedString = str.split(highlightRegex).reduce((acc, part, index) => {
            if (matches.includes(`<highlight>${part}</highlight>`)) {
                acc.push(<span className="highlight" key={index}>{part}</span>);
            } else {
                acc.push(part);
            }
            return acc;
        }, []);

        return parsedString;
    };

    return(
        <List
            itemLayout="vertical"
            size="large"
            bordered={true}
            style={{
                backgroundColor: '#FAFAFA',
                boxShadow: '0 25px 25px rgba(0, 0, 0, 0.15)',
                display: searchResultVisible ? '' :  'none'
            }}
            pagination={{
                onChange: (page) => {
                },
                pageSize: 3,
            }}
            dataSource={data}
            renderItem={(item) => {
                return item.title === '#NO_MATCH#' ? (
                    <List.Item>
                        Không tìm thấy bài viết nào có từ khóa: <strong>{item.q}</strong>
                    </List.Item>
                ) : (
                    <List.Item
                        key={item.id}
                        actions={[
                            <IconText icon={StarOutlined} text="156" key="list-vertical-star-o" />,
                            <IconText icon={LikeOutlined} text="156" key="list-vertical-like-o" />,
                        ]}
                    >
                        <List.Item.Meta
                            title={<a href={`/article/view/${item.id}`}>{item.title}</a>}
                            description={`Danh mục: ${item.category}`}
                        />
                        {parseStringWithHighlight(item.abstract)}
                    </List.Item>
                );
            }}
        />
    )
}

export default SearchResult
