import {debounce} from "lodash";
import {useEffect, useRef, useState} from "react";
import ajax from "../Utils/APICall";
import {SearchOutlined} from "@ant-design/icons";
import {Button,  Input, Space} from "antd";
import {notify} from "../Utils/Dialog";
import {waitFor} from "../../Common";
import SearchResult from "./SearchResult";
import {useNavigate} from "react-router-dom";

const KBSearchBox = () => {

    const [searchButtonState, setSearchButtonState] = useState(false);
    const [searchResult, setSearchResult] = useState([]);
    const [searchResultVisible, setSearchResultVisible] = useState(false);

    const searchRef = useRef(null);

    useEffect(() => {
        waitFor(searchRef.current).then(searchRef.current.focus());
    }, [])

    const loadOptionsDebounced = debounce((inputValue, callback) => {
        ajax.post('/search', {q: inputValue}).then(resp => {
            if (resp) {
                callback(resp);
            }
        })
    }, 500)

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            display: 'none',
            height: '50px',
            border: `1px solid ${state.isFocused ? '#C32126' : '#ccc'}`,
            boxShadow: state.isFocused ? `0 0 0 1px #C32126` : null,
            '&:hover': {
                border: `1px solid ${state.isFocused ? '#C32126' : '#999'}`,
                cursor: 'pointer', // Change the cursor to "hand"
            },
        }),
    };

    const searchInputChange = (e) => {
        const input = e.target?.value;

        if (!input || (input.trim().length == 0)){
            setSearchResultVisible(false);
            setSearchResult([]);
        }
    }

    const doSearch = () => {
        const value = searchRef?.current?.input?.value ?? searchRef.current.input.value;

        if (!value || (value.trim().length < 3)){
            notify("error", "Nội dung tìm kiếm phải dài ít nhất 3 ký tự");
            return;
        }

        setSearchButtonState(true);
        ajax.post('/search', {q: value}).then(resp => {
            if (resp && (typeof resp === 'object')){
                if (resp.length === 0){
                    resp.push({
                        title: '#NO_MATCH#',
                        q: value
                    })
                }


                setSearchResult(resp);
                setSearchResultVisible(true);
            }
        }).finally(() => setSearchButtonState(false));
    }

    return (
        <div>
            <Space.Compact block>
                <Input
                    ref={searchRef}
                    placeholder="Nhập vấn đề bạn cần tìm kiếm..."
                    onChange={searchInputChange}
                    onPressEnter={doSearch}
                    prefix={<SearchOutlined />}
                    allowClear="true"
                    style={{height: '50px'}}
                />
                <Button onClick={doSearch} style={{height: '50px'}} disabled={searchButtonState} loading={searchButtonState}>Tìm thông tin</Button>
            </Space.Compact>
            <SearchResult data={searchResult} searchResultVisible={searchResultVisible}/>
        </div>
    );
}
const Home = () =>{
    const [categoryList, setCategoryList] = useState([])
    const navigate = useNavigate();

    useEffect(() => {
        ajax.post('/category/get/home').then(resp => {
            if (typeof resp === 'object')
                setCategoryList(resp);
        });
    },[])

    return (
        <div>
            <div style={{paddingTop: "20px"}}></div>
            <div className="logout-text" style={{fontSize: "2em"}}>
                Bạn cần trợ giúp về vấn đề gì?
            </div>
            <div style={{paddingTop: "30px", cursor: "hand"}}><KBSearchBox /></div>
            <Space
                direction="vertical"
                size="middle"
                style={{
                    display: 'flex',
                    marginTop: '10px'
                }}>
                {/*categoryList.map((item) => (
                    <Card key={item._id} hoverable={true} style={{border: '1px solid #e5e7eb'}} onClick={() => {navigate(`/browse/${item._id}`)}}>
                        <div style={{display: 'flex'}}>
                            <h4>{item.categoryName}</h4>
                        </div>
                    </Card>
                ))*/}
            </Space>
        </div>
    );
}

export default Home;
